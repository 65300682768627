<template>

    <div>

        <div class="card">

            <h3>
                Agregar comprobante <span v-if="oc_id">para la OC {{ oc_id }}</span>
            </h3>

            <div class="card-body">

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="detail">Tipo</label>
                        <Dropdown id="client" v-model="factura.tipo" :options="tipos" class="w-full"
                        optionLabel="tipo"
                        
                            optionValue="code"   />
                    </div>

                    <div class="field col">
                        <label for="factura">N° de Comprobante</label>
                        <input type="text" v-model="factura.factura" id="Factura" required placeholder="A00000-00000000"
                            v-mask="'N#####-########'" v-on:blur="zerostofac" @keyup="keymonitor"
                            class="p-inputtext p-component p-filled inputfield w-full">
                    </div>

                    <div class="field col">
                        <label for="detail">Fecha de emisión</label>
                        <input type="date" v-model="factura.emision" id="emision" required
                            class="p-inputtext p-component p-filled inputfield w-full">
                    </div>
                </div>

                <div class="formgrid grid">

                    <!-- importe -->

                    <div class="field col-2">
                        <label for="detail">Importe</label>
                        <input type="number" v-model="factura.importe" id="importe" required
                            class="p-inputtext p-component p-filled inputfield w-full">
                    </div>

                    <!-- save button -->

                    <div class="field col my-auto">
                        <Button label="Guardar" icon="pi pi-save" class="p-button-success" @click="save" />
                    </div>

                </div>

            </div>

        </div>
        </div>  
</template>


<script>

import CrudService from '../../services/crud.service';

export default {

    data() {
        return {
            oc_id: null,
            selectedOP: '',
            factura: {
                id: '',
                oc: '',
                factura: 'A',
                vto: '',
                archivo: '',
                remito: '',
                emision: '',
            },
            tipos: [
                { code: "1", tipo: 'Factura' },
                { code: "2", tipo: 'Nota de Débito' },
                { code: "3", tipo: 'Nota de Crédito' }

            ],

        }
    },
    components: {
    },
    mounted() {
        // console.log(this.nros_oc)

        // check if route query param oc
        if (this.$route.query.oc) {
            this.oc_id = this.$route.query.oc
            this.factura.oc = this.$route.query.oc
        }
    },
    methods: {
        zerostofac() {

            let explode = this.factura.factura.split('-')

            let zeros = explode[0].padStart(5, '0')

            if (explode[1] == undefined) {
                explode[1] = '00000000'
            } else {

            }

            let zeros2 = explode[1].padStart(8, '0')
            this.factura.factura = zeros + '-' + zeros2

            if (!this.factura.factura.charAt(0).match(/[a-z]/i)) {
                this.factura.factura = 'A' + this.factura.factura
            }

            // set uppercase first letter
            this.factura.factura = this.factura.factura.charAt(0).toUpperCase() + this.factura.factura.slice(1)

        },
        keymonitor(event) {

            if (event.keyCode == 109) {


                // if this.factura.factura first char is a letter
                if (!this.factura.factura.charAt(0).match(/[a-z]/i)) {
                    //this.factura.factura[0] = 'A'
                    let start = 'A'
                    let end = this.factura.factura.padStart(5, '0')
                    this.factura.factura = start + end

                } else {
                    let letter = this.factura.factura.charAt(0)
                    let number = this.factura.factura.slice(1)
                    this.factura.factura = letter + number.padStart(5, '0')

                }


            }

            this.factura.factura = this.factura.factura.charAt(0).toUpperCase() + this.factura.factura.slice(1)
        },
        compType(tipo) {
            switch (tipo) {
                case "1":
                    return 'Factura'

                case "2":
                    return 'Nota de Débito'

                case "3":
                    return 'Nota de Crédito'

                case 1:
                    return 'Factura'

                case 2:
                    return 'Nota de Débito'

                case 3:
                    return 'Nota de Crédito'


            }
        },

        save(){


            CrudService.createCRUD('api','facrec', this.factura).then(
            (data) => {
                this.$router.push({ name: 'compras' })
            }
        )
        }
    }
    
}
</script>

